<template>
	<!-- 商家等级 -->
	<div class="storeLevel el-content">
		<a-button v-has="{action:'wxapp_marketing_store_level_add'}" @click="editLevel(true)" type="primary">
			<i class="ri-add-line ri-lg ri-top"></i>
			新增等级
		</a-button>
		<a-table :pagination="false" row-key="level_id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'level_id'},
			{title:'等级',dataIndex:'level_rank'},
			{title:'等级名称',dataIndex:'level_name',slots:{customRender:'level_name'}},
			{title:'最低销售额',dataIndex:'where_min_sale_amount'},
			{title:'最低销售量',dataIndex:'where_min_sale_number',slots:{customRender:'where_min_sale_number'}},
			{title:'最低评分',dataIndex:'where_min_sale_score'},
			{title:'等级描述',dataIndex:'level_desc'},
			{title:'创建时间',dataIndex:'create_time'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]">
			<template #level_name="{record}">
				<div style="display: flex;align-items: center;">
					<img :src="record.level_logo" style="width: 30px;height: 30px;margin-right: 5px;" alt="">
					<span>{{record.level_name}}</span>
				</div>
			</template>
			<template #where_min_sale_number="{record}">
				<span class="kdred">￥{{record.where_min_sale_amount}}</span>
			</template>
			<template #action="{record}">
				<a-space>
					<kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_marketing_store_level_add'}" @click="editLevel(record)"></kd-button>
					<kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_marketing_store_level_del'}" @click="deleteLevel(record.level_id)"></kd-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getLevelList(info.page,e)}"
				@change="(e)=>{getLevelList(e,info.limit)}"
			/>
		</div>
		<a-modal v-model:visible="show.level" title="添加商家等级" @ok="saveSubmit" @cancel="show.level = false" width="600px">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="等级排名">
					<a-input v-model:value="form.level_rank"></a-input>
				</a-form-item>
				<a-form-item label="等级名称">
					<a-input v-model:value="form.level_name"></a-input>
				</a-form-item>
				<a-form-item label="等级logo">
					<kd-img-select :src="form.level_logo" @change="(url)=>{ form.level_logo = url }"></kd-img-select>
				</a-form-item>
				<a-form-item label="等级描述">
					<a-input v-model:value="form.level_desc"></a-input>
				</a-form-item>
				<a-form-item label="最低销售量">
					<a-input v-model:value="form.where_min_sale_number" addon-after="单"></a-input>
				</a-form-item>
				<a-form-item label="最低销售额">
					<a-input v-model:value="form.where_min_sale_amount" addon-after="元"></a-input>
				</a-form-item>
				<a-form-item label="最低评分">
					<a-input v-model:value="form.where_min_sale_score"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import shopModel from "@/api/addons/shop.js"
import { reactive, toRefs } from 'vue'
export default{
	setup(){
		const _d = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			show:{ level:false, },
			form:null,
		})
		getLevelList(1,_d.info.limit)
		function getLevelList(page,limit){
			shopModel.getShopLevel(page,limit,res=>_d.info = {limit,...res})
		}
		function editLevel(row){
			_d.form = {
				level_id:row ? row.level_id : 0,
				level_rank:row ? row.level_rank : '',
				level_name:row ? row.level_name : '',
				level_logo:row ? row.level_logo : '',
				level_desc:row ? row.level_desc : '',
				where_min_sale_number:row ? row.where_min_sale_number : '',
				where_min_sale_amount:row ? row.where_min_sale_amount : '',
				where_min_sale_score:row ? row.where_min_sale_score : ''
			}
			_d.show.level = true
		}
		const deleteLevel = (id)=>shopModel.deleteShopLevel(id,()=>getLevelList(_d.info.page,_d.info.limit))
		const saveSubmit = ()=>shopModel.addOrEditShopLevel(_d.form,()=>{
			getLevelList(_d.info.page,_d.info.limit)
			_d.show.level = false

		})
		return{
			...toRefs(_d),
			getLevelList,
			deleteLevel,
			editLevel,
			saveSubmit
		}
	},
	methods:{
		
		
	}
}
</script>

<style>
</style>
